import React from 'react'
import { Button, Row, Col } from 'antd'
import AgiliteHeader from '../../reusables/components/agilite-header'
import { initHomePage } from 'core/core-actions'
import { useDispatch } from 'react-redux'

// TODO: The confirmation components have pretty much the same thing in common, except for button wording. Consider consolidating components into 1
// TODO: Use Enums where relevant
// TODO: Replace color codes with theme
const CoreForgotPasswordConfirmation = () => {
  const dispatch = useDispatch()

  return (
    <div>
      <Row type='flex' justify='center'>
        <Col xs={23} sm={23} md={16} lg={12} xl={10} xxl={8}>
          <center>
            <AgiliteHeader
              header='Password Reset Request Submitted'
              subheader='Your password reset request has been submitted. You will receive a notification shortly'
            />
            <Button
              style={{
                backgroundColor: '#67AD5B',
                color: 'white',
                width: 'auto'
              }}
              onClick={(e) => {
                e.preventDefault()
                dispatch(initHomePage())
              }}
            >
              Return to Home Page
            </Button>
          </center>
        </Col>
      </Row>
    </div>
  )
}

export default CoreForgotPasswordConfirmation
