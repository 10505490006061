import React from 'react'
import moment from 'moment'
import TypeDetect from 'agilite-utils/dist/type-detect'
import EnumsTypeDetect from 'agilite-utils/dist/enums-type-detect'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey, faTrash, faClone, faCopy, faRefresh } from '@fortawesome/free-solid-svg-icons'
import {
  Popconfirm,
  Table,
  Row,
  Col,
  Input,
  Button,
  message,
  Modal,
  Tooltip,
  Tag,
  Divider,
  DatePicker,
  Switch,
  Form,
  Popover,
  Select
} from 'antd'
import {
  SyncOutlined,
  RollbackOutlined,
  MailOutlined,
  CheckSquareOutlined,
  CloseSquareOutlined,
  ExportOutlined
} from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { uniqBy } from 'lodash'

import MemoryStore from '../../utils/memory-store'
import Enums from '../../utils/enums'
import Theme from '../../utils/agilite-theme'
import {
  fetchAllErrorLogs,
  handleMailAgilite,
  unresolveLog,
  resolveLog,
  handleExport,
  updateViewData,
  estimatedCount
} from '../../error-logs/error-logs-utils'
import { resetConnectionPool } from '../../connectors/connectors-utils'

// Custom Components
import ErrorLogsForm from '../../error-logs/components/error-logs-form'

const { TextArea } = Input
const { RangePicker } = DatePicker

class ListView extends React.Component {
  constructor(props) {
    super(props)

    this.appId = props.appId

    this.state = {
      viewData: this.props.viewData,
      currentViewData: this.props.viewData,
      searchFilter: this.props.searchFilter,
      viewColumns: this.props.viewColumns,
      modalOpen: false,
      apiKey: '',
      name: '',
      loading: false,
      columns: this.props.viewColumns,
      theme: this.props.theme,
      errorLogModalOpen: false,
      record: null,
      dateModalOpen: false,
      date: [moment().startOf('month'), moment()],
      tmpDate: null,
      includeResolvedLogs: false,
      selectedRowKeys: [],
      page: 1,
      pageLimit: 20,
      totalRecords: 0
    }

    this.fetchAllData = this.fetchAllData.bind(this)
    this.generateViewHeader = this.generateViewHeader.bind(this)
    this.createProfile = this.createProfile.bind(this)
    this.editProfile = this.editProfile.bind(this)
    this.deleteProfile = this.deleteProfile.bind(this)
    this.updateViewColumns = this.updateViewColumns.bind(this)
    this.reverseMapping = this.reverseMapping.bind(this)
    this.createCopy = this.createCopy.bind(this)
    this.generateApiKey = this.generateApiKey.bind(this)
    this.viewErrorLogDetail = this.viewErrorLogDetail.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleResetConnectionPool = this.handleResetConnectionPool.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.fetchAllData(this.state.date, this.state.includeResolvedLogs, this.state.page, this.state.pageLimit)
  }

  async fetchAllData(date, includeResolvedLogs, page, pageLimit) {
    let data = []
    let tmpTotalRecords = 0

    try {
      this.setState({ loading: true, dateModalOpen: false })

      switch (this.appId) {
        case Enums.APP_IDS.ERROR_LOGS:
          tmpTotalRecords = await estimatedCount(date, includeResolvedLogs)
          data = await fetchAllErrorLogs(date, includeResolvedLogs, page, pageLimit)
          this.props.setErrorLogsData(data)

          this.setState({ loading: false, currentViewData: data, totalRecords: tmpTotalRecords })
          break
        default:
          this.props.fetchAllData(this.props.appId, (success, error) => {
            if (success) {
              this.updateViewColumns(this.props)
            } else {
              message.error(error)
            }

            this.setState({ loading: false })
          })
      }
    } catch (error) {
      this.setState({ loading: false })
      message.error(error)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.appId !== nextProps.appId) {
      return null
    }

    this.updateViewColumns(nextProps)

    this.setState({
      viewData: nextProps.viewData,
      searchFilter: nextProps.searchFilter,
      viewColumns: nextProps.viewColumns
    })
  }

  generateApiKey(id, name) {
    const tmpThis = this
    let msg = null

    tmpThis.props.onGenerateApiKey(id, (err, result) => {
      if (err && err.response.data) {
        msg = err.response.data.errorMessage
      }

      if (msg) {
        message.error(msg)
      } else {
        const state = {
          modalOpen: true,
          name: name,
          apiKey: result.data
        }

        tmpThis.setState(state)
      }
    })
  }

  viewErrorLogDetail(record) {
    const tmpThis = this

    tmpThis.setState({ errorLogModalOpen: true, record })
  }

  reverseMapping(recordData) {
    const tmpThis = this

    tmpThis.props.onReverseMapping(recordData, function (result) {
      if (result) {
        // Setup Content property and Add Tab
        result.content = <result.content />
        tmpThis.props.addTab(result)
      }
    })
  }

  createCopy(recordData) {
    const tmpThis = this

    tmpThis.props.onCreateCopy(recordData, function (result) {
      if (result) {
        // Setup Content property and Add Tab
        result.content = <result.content />
        tmpThis.props.addTab(result)
      }
    })
  }

  updateViewColumns(props) {
    // Add Intelligence to relevant Columns
    const columns = props.viewColumns

    for (const x in columns) {
      // Check which column needs to be grouped
      if (columns[x].groupable) {
        for (const propName in props) {
          if (propName === columns[x].key + 'Filter') {
            columns[x].filters = props[propName]
          }
        }
      }
    }

    this.setState({ columns })
  }

  createProfile() {
    const tmpThis = this

    tmpThis.props.createProfile(tmpThis.props.appId, function (result) {
      if (result) {
        // Setup Content property and Add Tab
        result.content = <result.content />
        tmpThis.props.addTab(result)
      }
    })
  }

  editProfile(recordId) {
    const tmpThis = this

    tmpThis.props.editProfile(tmpThis.props.appId, recordId, this.props.viewData, function (result) {
      if (result) {
        // Setup Content property and Add Tab
        result.content = <result.content />
        tmpThis.props.addTab(result)
      }
    })
  }

  deleteProfile(recordId) {
    this.props.deleteProfile(this.props.appId, recordId, this.props.state, (err) => {
      if (err) {
        message.error(err)
      }
    })
  }

  async handleResetConnectionPool(profileKey) {
    try {
      await resetConnectionPool(profileKey)
      message.success('Connection Pool successfully reset')
    } catch (e) {
      message.error(e)
    }
  }

  determinePrivileges() {
    if (MemoryStore.userProfile.role === 'Admin') {
      return true
    } else if (
      MemoryStore.userProfile.role === 'User' &&
      MemoryStore.userProfile.teamPrivileges.connectors === 'Editor'
    ) {
      return true
    }

    return false
  }

  handleConfirm(value, logIds) {
    return new Promise((resolve, reject) => {
      ;(async () => {
        try {
          Modal.confirm({
            title: 'Confirmation',
            content: `Are you sure you want to mark all logs as ${value === '1' ? 'Resolved' : 'Unresolved'}`,
            type: 'confirm',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
              if (value === '1') {
                await resolveLog(logIds, Enums.VALUES_STRINGS.ALL)
              } else {
                await unresolveLog(logIds, Enums.VALUES_STRINGS.ALL)
              }

              resolve(true)
            },
            onCancel: () => resolve(false)
          })
        } catch (e) {
          reject(e)
        }
      })()
    })
  }

  async markAllResolvedUnresolved(value) {
    try {
      let tmpData = null
      let logIds = ''
      let confirmFlag = null

      if (!value) {
        return
      } else if (value === '0' || value === '2') {
        logIds = this.state.selectedRowKeys.concat()
      } else {
        logIds = this.state.viewData.map((entry) => entry._id)
      }

      switch (value) {
        case '0': // Mark selected as resolved
          await resolveLog(logIds, Enums.VALUES_STRINGS.SPECIFIC)
          tmpData = updateViewData(this.state.viewData, logIds, 'resolve')
          confirmFlag = true
          break
        case '1': // Mark all as resolved
          confirmFlag = await this.handleConfirm(value, logIds)
          if (confirmFlag) tmpData = updateViewData(this.state.viewData, logIds, 'resolve')
          break
        case '2': // Mark selected as unresolved
          await unresolveLog(logIds, Enums.VALUES_STRINGS.SPECIFIC)
          tmpData = updateViewData(this.state.viewData, logIds, 'unresolve')
          confirmFlag = true
          break
        case '3': // Mark all as unresolved
          confirmFlag = await this.handleConfirm(value, logIds)
          if (confirmFlag) tmpData = updateViewData(this.state.viewData, logIds, 'unresolve')
          break
        default:
          break
      }

      if (confirmFlag) this.setState({ selectedRowKeys: [], viewData: tmpData })
    } catch (e) {
      message.error(e)
    }
  }

  generateViewHeader() {
    let btnText = ''

    switch (this.props.appId) {
      case 'teamusers':
        btnText = 'Invite User'
        break
      default:
        btnText = 'Create New'
    }

    return (
      <div>
        <Row justify='space-between'>
          {!this.props.createNewHidden ? (
            <Col xs={7} sm={5} md={4} lg={4} xl={3} xxl={2} style={{ textAlign: 'left' }}>
              <Button
                disabled={MemoryStore.userProfile.teamPrivileges[this.props.appId] === 'Reader'}
                style={{ backgroundColor: '#67AD5B', color: 'white' }}
                onClick={(e) => {
                  e.preventDefault()
                  this.createProfile()
                }}
              >
                {btnText}
              </Button>
            </Col>
          ) : null}
          {this.appId === Enums.APP_IDS.ERROR_LOGS ? (
            <Col span={3}>
              <Form.Item>
                <Select value='' onChange={(value) => this.markAllResolvedUnresolved(value)}>
                  <Select.Option value=''>-Select Action-</Select.Option>
                  {/* eslint-disable-next-line */}
                  <Select.Option value='0' disabled={this.state.selectedRowKeys.length > 0 ? false : true}>
                    Mark selected as resolved
                  </Select.Option>
                  <Select.Option value='1'>Mark all as resolved</Select.Option>
                  {/* eslint-disable-next-line */}
                  <Select.Option value='2' disabled={this.state.selectedRowKeys.length > 0 ? false : true}>
                    Mark selected as unresolved
                  </Select.Option>
                  <Select.Option value='3'>Mark all as unresolved</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          ) : null}
          {this.appId === Enums.APP_IDS.ERROR_LOGS ? (
            <Col xs={7} sm={5} md={3} lg={3} xl={3} xxl={3}>
              <Tooltip title='Click to change Date Range'>
                <Input
                  style={{ float: 'left', width: 210, cursor: 'pointer' }}
                  value={`${moment(this.state.date[0]).format('YYYY-MM-DD')} - ${moment(this.state.date[1]).format(
                    'YYYY-MM-DD'
                  )}`}
                  onClick={() => this.setState({ dateModalOpen: true, tmpDate: this.state.date })}
                  readOnly
                />
              </Tooltip>
            </Col>
          ) : null}
          {this.appId === Enums.APP_IDS.ERROR_LOGS ? (
            <Col xs={4} sm={4} md={3} lg={3} xl={3} xxl={3}>
              <Form.Item label='Include Resolved Logs'>
                <Switch
                  style={{ float: 'left' }}
                  defaultChecked={false}
                  onChange={(value) => {
                    this.setState({ includeResolvedLogs: value })
                    this.fetchAllData(this.state.date, value)
                  }}
                />
              </Form.Item>
            </Col>
          ) : null}
          <Col xs={13} sm={14} md={16} lg={16} xl={19} xxl={this.props.createNewHidden ? 8 : 20}>
            <Input
              name='search'
              placeholder={`Search ${this.props.viewTitle}...`}
              defaultValue={this.props.searchFilter}
              onChange={(e) => {
                this.props.setSearchFilter(e.target.value)
              }}
            />
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={2} xxl={2}>
            <Tooltip title='Refresh View Data'>
              <Button
                name='refresh'
                type='default'
                onClick={(e) => {
                  e.preventDefault()
                  this.fetchAllData(
                    this.state.date,
                    this.state.includeResolvedLogs,
                    this.state.page,
                    this.state.pageLimit
                  )
                }}
              >
                <SyncOutlined
                  style={{ fontSize: 20, fontWeight: 'bolder', color: '#67AD5B' }}
                  spin={this.state.loading}
                />
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </div>
    )
  }

  handleDateChange(date) {
    if (date) this.setState({ tmpDate: date })
  }

  render() {
    const columns = []
    const tmpThis = this
    let tmpObj = {}
    let data = null
    let tmpValue = null

    for (const x in this.state.columns) {
      tmpObj = JSON.parse(JSON.stringify(this.state.columns[x]))

      // Add render function to all columns at first
      // The element rendered here will ensure that the content does not exceed width
      tmpObj.render = (value) => {
        if (TypeDetect(value) === EnumsTypeDetect.OBJECT) {
          tmpValue = value.message || value.errorMessage || (
            <i style={{ color: Theme.secondary }}>See Log Detail for more information...</i>
          )

          return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{tmpValue}</div>
        } else {
          return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{value}</div>
        }
      }

      // Check for width prop
      if (this.state.columns[x].width) tmpObj.width = this.state.columns[x].width

      // Show Solutions
      if (this.state.columns[x].key === 'solutions') {
        tmpObj.render = (text, record) => {
          if (record.data.solutions) {
            return record.data.solutions.map((entry, index) => {
              return (
                <Tag style={{ margin: 2 }} color='geekblue' key={index}>
                  {entry}
                </Tag>
              )
            })
          }
        }
      }

      // Check which column is used to edit the record and copy the key
      if (this.state.columns[x].editRecord && this.state.columns[x].copyKey) {
        tmpObj.render = (text, record) => {
          return {
            props: {
              style: { cursor: 'copy' }
            },
            children: (
              <div>
                <a
                  onClick={() => {
                    if (this.appId === Enums.APP_IDS.ERROR_LOGS) {
                      this.viewErrorLogDetail(record)
                    } else {
                      this.editProfile(record._id)
                    }
                  }}
                >
                  {text}
                </a>
              </div>
            )
          }
        }

        tmpObj.onCell = (record) => {
          return {
            onClick: (e) => {
              if (!(e.target instanceof HTMLAnchorElement)) {
                if (this.appId === Enums.APP_IDS.ERROR_LOGS) {
                  navigator.clipboard.writeText(record._id)
                } else {
                  navigator.clipboard.writeText(record.data ? record.data.key : record.key)
                }

                message.info('Value copied to clipboard')
              }
            }
          }
        }
      } else if (this.state.columns[x].editRecord) {
        tmpObj.render = (text, record) => {
          return (
            <div>
              <a
                onClick={() => {
                  if (this.appId === Enums.APP_IDS.ERROR_LOGS) {
                    this.viewErrorLogDetail(record)
                  } else {
                    this.editProfile(record._id)
                  }
                }}
              >
                {text}
              </a>
            </div>
          )
        }
      } else if (this.state.columns[x].copyKey) {
        tmpObj.render = (text) => {
          return {
            props: {
              style: { cursor: 'copy' }
            },
            children: <div>{text}</div>
          }
        }

        tmpObj.onCell = (record) => {
          return {
            onClick: () => {
              navigator.clipboard.writeText(record.data ? record.data.key : record.key)
              message.info('Value copied to clipboard')
            }
          }
        }
      }

      if (this.state.columns[x].groupable) {
        // eslint-disable-next-line
        tmpObj.onFilter = function (value, record) {
          data = record.data ? record.data : record
          return data[tmpThis.state.columns[x].key].toLowerCase() === value.toLowerCase()
        }
      }

      if (this.state.columns[x].sortEnabled) {
        if (this.state.columns[x].sortMethod) tmpObj.sorter = this.state.columns[x].sortMethod
        if (this.state.columns[x].defaultSortOrder) tmpObj.defaultSortOrder = this.state.columns[x].defaultSortOrder
      }

      if (this.state.columns[x].renderMethod) {
        tmpObj.render = this.state.columns[x].renderMethod
      }

      // Check if filters are enabled
      if (this.state.columns[x].filterEnabled) {
        // Check if a filter method was provided
        if (this.state.columns[x].filterMethod) tmpObj.onFilter = this.state.columns[x].filterMethod
        // Check if a filter prop was provided
        if (this.state.columns[x].filterProp) {
          tmpObj.filters = this.state.currentViewData.map((entry) => {
            if (entry.data) {
              return {
                text: entry.data[this.state.columns[x].filterProp],
                value: entry.data[this.state.columns[x].filterProp]
              }
            } else {
              return { text: entry[this.state.columns[x].filterProp], value: entry[this.state.columns[x].filterProp] }
            }
          })

          // Remove duplicate filters
          tmpObj.filters = uniqBy(tmpObj.filters, 'value')
        }
      }

      if (this.state.columns[x].isActions) {
        // eslint-disable-next-line
        tmpObj.render = (text, record) => {
          return (
            <div style={{ minWidth: 50, maxHeight: 50 }}>
              {/* //First Delete Action */}
              {this.state.columns[x].actionTypes.indexOf('delete') > -1 &&
              MemoryStore.userProfile.teamPrivileges[this.props.appId] !== 'Reader' ? (
                <div
                  className={
                    process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'
                  }
                >
                  <Popconfirm
                    title='Are you sure delete this Profile?'
                    onConfirm={() => {
                      this.deleteProfile(record._id)
                    }}
                    okText='Yes'
                    cancelText='No'
                  >
                    {/* eslint-disable-next-line */}
                    <a title='Delete Profile'>
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ color: tmpThis.state.theme.dangerColor, marginRight: 10 }}
                      />
                    </a>
                  </Popconfirm>
                </div>
              ) : null}
              {/* //Then Generate API Keys Action */}
              {this.state.columns[x].actionTypes.indexOf('generate-api-key') > -1 ? (
                <div
                  className={
                    process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'
                  }
                  style={{ marginLeft: 10, marginRight: 10 }}
                >
                  {/* eslint-disable-next-line */}
                  <a title='Generate API Key'>
                    <FontAwesomeIcon
                      icon={faKey}
                      onClick={() => this.generateApiKey(record._id, record.firstName)}
                      style={{ color: tmpThis.state.theme.successColor }}
                    />
                  </a>
                </div>
              ) : null}
              {/* Reverse Mapping */}
              {this.state.columns[x].actionTypes.indexOf('reverse-mapping') > -1 ? (
                <div
                  className={
                    process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'
                  }
                  style={{ marginLeft: 10, marginRight: 10 }}
                >
                  {/* eslint-disable-next-line */}
                  <a title='Reverse Mapping'>
                    <FontAwesomeIcon
                      icon={faRefresh}
                      onClick={() => this.reverseMapping(record.data)}
                      style={{ color: tmpThis.state.theme.successColor }}
                    />
                  </a>
                </div>
              ) : null}
              {/* Create Copy */}
              {this.state.columns[x].actionTypes.indexOf('create-copy') > -1 ? (
                <div
                  className={
                    process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'
                  }
                  style={{ marginLeft: 10, marginRight: 10 }}
                >
                  {/* eslint-disable-next-line */}
                  <a title='Create Copy'>
                    <FontAwesomeIcon
                      icon={faClone}
                      onClick={() => this.createCopy(record.data)}
                      style={{ color: tmpThis.state.theme.primaryLight }}
                    />
                  </a>
                </div>
              ) : null}
              {/* Mark Resolved/Unresolved */}
              {this.state.columns[x].actionTypes.indexOf('resolvedUnresolved') > -1 ? (
                <div>
                  <center>
                    <a title={`Mark as ${record.data.resolvedDate ? 'Unresolved' : 'Resolved'}`}>
                      {record.data.resolvedDate ? (
                        <Popover
                          title={<p>Resolved By {record.data.resolvedBy}</p>}
                          content={() => {
                            return (
                              <div>
                                <p>Date: {moment(new Date(record.data.resolvedDate)).format('YYYY-MM-DD HH:mm:ss')}</p>
                              </div>
                            )
                          }}
                        >
                          <CheckSquareOutlined
                            style={{ fontSize: 20, color: Theme.successColor }}
                            onClick={async () => {
                              let viewData = null

                              try {
                                await unresolveLog([record._id], Enums.VALUES_STRINGS.SPECIFIC)
                                viewData = updateViewData(this.state.viewData, [record._id], 'unresolve')
                                this.setState({ viewData })
                              } catch (e) {
                                message.error(e)
                              }
                            }}
                          />
                        </Popover>
                      ) : (
                        <CloseSquareOutlined
                          style={{ fontSize: 20, color: Theme.dangerColor }}
                          onClick={async () => {
                            let viewData = null

                            try {
                              await resolveLog([record._id], Enums.VALUES_STRINGS.SPECIFIC)
                              viewData = updateViewData(this.state.viewData, [record._id], 'resolve')
                              this.setState({ viewData })
                            } catch (e) {
                              message.error(e)
                            }
                          }}
                        />
                      )}
                    </a>
                  </center>
                </div>
              ) : null}
              {/* Email Agilit-e */}
              {this.state.columns[x].actionTypes.indexOf('email') > -1 ? (
                <div
                  className={
                    process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'
                  }
                >
                  {/* eslint-disable-next-line */}
                  <Popconfirm
                    title='Are you sure you want to send this Log to Agilit-e?'
                    onConfirm={async () => {
                      try {
                        await handleMailAgilite(record)
                        message.success('Email successfully sent')
                      } catch (e) {
                        message.error(e)
                      }
                    }}
                  >
                    <a title='Email Agilit-e'>
                      <MailOutlined style={{ fontSize: 16, color: Theme.warningColor }} />
                    </a>
                  </Popconfirm>
                </div>
              ) : null}
              {this.state.columns[x].actionTypes.indexOf('reset-connection-pool') > -1 && this.determinePrivileges() ? (
                <div
                  className={
                    process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'
                  }
                  style={{ marginLeft: 10, marginRight: 10 }}
                >
                  {/* eslint-disable-next-line */}
                  <Popconfirm
                    title={`Are you sure you want to reset the Connection Pool for profile: '${record.data.key}'`}
                    onConfirm={() => this.handleResetConnectionPool(record.data.key)}
                  >
                    <a title='Reset Connection Pool'>
                      <RollbackOutlined style={{ cursor: 'pointer', color: Theme.warningColor }} />
                    </a>
                  </Popconfirm>
                </div>
              ) : null}
              {/* Export Log Entry */}
              {columns[x].actionTypes.indexOf('export') > -1 ? (
                <div
                  className={
                    process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'
                  }
                >
                  {/* eslint-disable-next-line */}
                  <Divider type='vertical' />
                  <span
                    onClick={async () => {
                      try {
                        this.setState({ loading: true })
                        await handleExport(record.data)
                        this.setState({ loading: false })
                      } catch (e) {
                        message.error(e)
                        this.setState({ loading: false })
                      }
                    }}
                  >
                    <a title='Export Log Entry'>
                      <ExportOutlined style={{ fontSize: 16, color: Theme.dangerColor }} />
                    </a>
                  </span>
                </div>
              ) : null}
            </div>
          )
        }
      }

      columns.push(tmpObj)
    }

    return (
      <div>
        <Row type='flex' justify='center'>
          <Col xs={23}>
            <Table
              loading={this.state.loading}
              columns={columns}
              dataSource={this.state.viewData}
              onChange={(pagination, filters, sorter, data) => {
                this.setState({ currentViewData: data.currentDataSource })
              }}
              bordered
              showHeader
              title={this.generateViewHeader}
              rowKey={(record) => (record._id ? record._id : record.key)}
              size='middle'
              rowSelection={
                this.appId === Enums.APP_IDS.ERROR_LOGS
                  ? {
                      selectedRowKeys: this.state.selectedRowKeys,
                      onChange: (selectedRowKeys) => this.setState({ selectedRowKeys })
                    }
                  : null
              }
              pagination={
                this.appId === Enums.APP_IDS.ERROR_LOGS
                  ? {
                      current: this.state.page,
                      pageSize: this.state.pageLimit,
                      showSizeChanger: true,
                      onChange: (page, pageSize) => {
                        this.setState({ page, pageLimit: pageSize })
                        this.fetchAllData(this.state.date, this.state.includeResolvedLogs, page, pageSize)
                      },
                      total: this.state.totalRecords
                    }
                  : false
              }
            />
          </Col>
        </Row>
        <Modal
          title={`API Key for ${this.state.name}`}
          okButtonProps={{ style: { backgroundColor: '#67AD5B', borderColor: '#67AD5B' } }}
          visible={this.state.modalOpen}
          onOk={() => {
            this.setState({ modalOpen: false })
          }}
          onCancel={() => {
            this.setState({ modalOpen: false })
          }}
          maskClosable={false}
        >
          <TextArea defaultValue={this.state.apiKey} disabled rows={4} />
          <CopyToClipboard text={this.state.apiKey} onCopy={() => message.info('API Key copied to clipboard')}>
            <div style={{ textAlign: 'center', marginTop: 20 }}>
              <FontAwesomeIcon icon={faCopy} style={{ fontSize: 20, cursor: 'pointer', color: '#67AD5B' }} />
            </div>
          </CopyToClipboard>
        </Modal>
        {this.state.record ? (
          <Modal
            title='Log Detail'
            okButtonProps={{ hidden: true }}
            visible={this.state.errorLogModalOpen}
            onOk={() => {
              this.setState({ errorLogModalOpen: false })
            }}
            onCancel={() => {
              this.setState({ errorLogModalOpen: false })
            }}
            cancelText='Close'
            maskClosable={false}
            width='50%'
            destroyOnClose
          >
            <ErrorLogsForm
              record={this.state.record}
              categoryTitle='Module'
              subCategoryTitle='Endpoint'
              messageTitle='Error Message'
              statusTitle='Status Code'
            />
          </Modal>
        ) : null}
        <Modal
          title='Date Range'
          onOk={() => {
            this.setState({ date: this.state.tmpDate, dateModalOpen: false })
            this.fetchAllData(this.state.tmpDate, this.state.includeResolvedLogs)
          }}
          onCancel={() => this.setState({ dateModalOpen: false, tmpDate: this.state.date })}
          okText='Apply'
          okButtonProps={{ type: 'default', style: { backgroundColor: Theme.successColor, color: Theme.white } }}
          maskClosable={false}
          destroyOnClose
          visible={this.state.dateModalOpen}
        >
          <RangePicker
            style={{ width: '90%' }}
            value={this.state.tmpDate}
            onChange={(date) => this.handleDateChange(date)}
            inputReadOnly
          />
        </Modal>
      </div>
    )
  }
}

export default ListView
