import React from 'react'
import TypeDetect from 'agilite-utils/dist/type-detect'
import EnumsTypeDetect from 'agilite-utils/dist/enums-type-detect'
import moment from 'moment'
import uniqBy from 'lodash/uniqBy'
import {
  message,
  Form,
  Button,
  Col,
  Row,
  Switch,
  Select,
  Input,
  Modal,
  Table,
  Popover,
  Tooltip,
  DatePicker,
  Popconfirm,
  Divider
} from 'antd'
import {
  CheckOutlined,
  CheckSquareOutlined,
  CloseSquareOutlined,
  ExportOutlined,
  MailOutlined,
  SyncOutlined
} from '@ant-design/icons'

import Theme from '../../utils/agilite-theme'
import Enums from '../../utils/enums'

import { columnTemplates } from '../../utils/templates'
import { filterLogViewData, unresolveLog, resolveLog } from '../batch-logging-utils'
import { handleExport, handleMailAgilite, updateViewData } from '../../error-logs/error-logs-utils'

import ErrorLogsForm from '../../error-logs/components/error-logs-form'

class BatchLoggingLogView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tableLoading: false,
      data: props.logViewData || [],
      currentTableData: props.logViewData || [],
      date: [moment().startOf('month'), moment()],
      tmpDate: '',
      dateModalOpen: false,
      includeResolvedLogs: false,
      errorLogModalOpen: false,
      record: null,
      selectedRowKeys: []
    }

    // Setup event binding
    this.handleGetLogsExtended = this.handleGetLogsExtended.bind(this)
    this.generateTableHeader = this.generateTableHeader.bind(this)
    this.generateColumns = this.generateColumns.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.viewErrorLogDetail = this.viewErrorLogDetail.bind(this)
    this.markEntryResolvedUnresolved = this.markEntryResolvedUnresolved.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.markAllResolvedUnresolved = this.markAllResolvedUnresolved.bind(this)
    this.mailAgilite = this.mailAgilite.bind(this)
  }

  componentDidMount() {
    if (!this.props.isNewDoc) this.handleGetLogsExtended(this.state.date, this.state.includeResolvedLogs)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props.logViewData) !== JSON.stringify(nextProps.logViewData)) {
      this.setState({ data: nextProps.logViewData, currentTableData: nextProps.logViewData })
    }
  }

  async handleGetLogsExtended(date, includeResolvedLogs) {
    let data = []

    try {
      if (this.props.isNewDoc) return

      this.setState({ tableLoading: true })

      data = await this.props.handleGetLogs(date, includeResolvedLogs)

      this.setState({ tableLoading: false, data, currentTableData: data })
      this.data = data
    } catch (err) {
      this.setState({ tableLoading: false })
      message.error(err)
    }
  }

  handleDateChange(date) {
    if (date) this.setState({ tmpDate: date })
  }

  viewErrorLogDetail(record) {
    const tmpThis = this
    tmpThis.setState({ errorLogModalOpen: true, record })
  }

  async markEntryResolvedUnresolved(record) {
    try {
      this.setState({ loading: true })

      if (record.data.resolvedDate) {
        await unresolveLog([record._id], Enums.VALUES_STRINGS.SPECIFIC)
      } else {
        await resolveLog([record._id], Enums.VALUES_STRINGS.SPECIFIC)
      }

      this.handleGetLogsExtended(this.state.date, this.state.includeResolvedLogs)
    } catch (e) {
      this.setState({ loading: false })
      message.error(e)
    }
  }

  handleConfirm(value, logIds) {
    return new Promise((resolve, reject) => {
      ;(async () => {
        try {
          Modal.confirm({
            title: 'Confirmation',
            content: `Are you sure you want to mark all logs as ${value === '1' ? 'Resolved' : 'Unresolved'}`,
            type: 'confirm',
            onOk: async () => {
              if (value === '1') {
                await resolveLog(logIds, Enums.VALUES_STRINGS.ALL)
              } else {
                await unresolveLog(logIds, Enums.VALUES_STRINGS.ALL)
              }

              resolve()
            },
            onCancel: () => resolve()
          })
        } catch (e) {
          reject(e)
        }
      })()
    })
  }

  async markAllResolvedUnresolved(value) {
    try {
      let tmpData = null
      let logIds = ''
      let confirmFlag = null

      if (!value) {
        return
      } else if (value === '0' || value === '2') {
        logIds = this.state.selectedRowKeys.concat()
      } else {
        logIds = this.state.data.map((entry) => entry._id)
      }

      switch (value) {
        case '0': // Mark selected as resolved
          await resolveLog(logIds, Enums.VALUES_STRINGS.SPECIFIC)
          tmpData = updateViewData(this.state.data, logIds, 'resolve')
          confirmFlag = true
          break
        case '1': // Mark all as resolved
          await this.handleConfirm(value, logIds)
          tmpData = updateViewData(this.state.data, logIds, 'resolve')
          if (confirmFlag) tmpData = updateViewData(this.state.viewData, logIds, 'resolve')
          break
        case '2': // Mark selected as unresolved
          await unresolveLog(logIds, Enums.VALUES_STRINGS.SPECIFIC)
          tmpData = updateViewData(this.state.data, logIds, 'unresolve')
          confirmFlag = true
          break
        case '3': // Mark all as unresolved
          await this.handleConfirm(value, logIds)
          tmpData = updateViewData(this.state.data, logIds, 'unresolve')
          if (confirmFlag) tmpData = updateViewData(this.state.viewData, logIds, 'unresolve')
          break
        default:
          break
      }

      if (confirmFlag) this.setState({ selectedRowKeys: [], data: tmpData })
    } catch (e) {
      message.error(e)
    }
  }

  async mailAgilite(record) {
    try {
      await handleMailAgilite(record)
      message.success('Email successfully sent')
    } catch (e) {
      message.error(e)
    }
  }

  generateColumns() {
    const columns = JSON.parse(JSON.stringify(columnTemplates.errorlogs))
    let tmpValue = null

    for (const x in columns) {
      columns[x].render = (value) => {
        if (TypeDetect(value) === EnumsTypeDetect.OBJECT) {
          tmpValue = value.message || value.errorMessage || (
            <i style={{ color: Theme.secondary }}>See Log Detail for more information...</i>
          )

          return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{tmpValue}</div>
        } else {
          return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{value}</div>
        }
      }

      if (columns[x].renderMethod) columns[x].render = columns[x].renderMethod

      if (columns[x].key === 'moduleName') columns[x].title = 'Category'
      if (columns[x].key === 'uri') columns[x].title = 'Sub Category'
      if (columns[x].key === 'response') columns[x].title = 'Message'
      if (columns[x].key === 'responseCode') columns[x].title = 'Code'
      if (columns[x].key === 'id') {
        columns[x].render = (text, record) => {
          return {
            props: {
              style: { cursor: 'copy' }
            },
            children: <a onClick={() => this.viewErrorLogDetail(record)}>{text}</a>
          }
        }

        columns[x].onCell = (record) => {
          return {
            onClick: (e) => {
              if (!(e.target instanceof HTMLAnchorElement)) {
                navigator.clipboard.writeText(record._id)
                message.info('Value copied to clipboard')
              }
            }
          }
        }
      }
      if (columns[x].key === 'createdAt') columns[x].render = (text) => moment(text).format('YYYY-MM-DD HH:mm')

      if (columns[x].filterEnabled) {
        if (columns[x].key === 'responseCode') {
          columns[x].onFilter = (value, record) => record.data[columns[x].filterProp] === value
        } else {
          columns[x].onFilter = (value, record) => record.data[columns[x].filterProp].indexOf(value) === 0
        }

        columns[x].filters = this.state.currentTableData?.map((entry) => {
          if (entry.data) {
            return { text: entry.data[columns[x].filterProp], value: entry.data[columns[x].filterProp] }
          } else {
            return { text: entry[columns[x].filterProp], value: entry[columns[x].filterProp] }
          }
        })

        // Remove duplicate filters
        columns[x].filters = uniqBy(columns[x].filters, 'value')
      }

      if (columns[x].key === 'resolved') {
        columns[x].render = (text, record) => {
          if (record.data.resolvedDate) {
            return (
              <Popover
                title={<p>Resolved By {record.data.resolvedBy}</p>}
                content={() => (
                  <div>
                    <p>Date: {moment(new Date(record.data.resolvedDate)).format('YYYY-MM-DD HH:mm:ss')}</p>
                  </div>
                )}
              >
                <center>
                  <CheckOutlined style={{ color: Theme.successColor, fontSize: 20 }} />
                </center>
              </Popover>
            )
          } else {
            return null
          }
        }
      }

      if (columns[x].isActions) {
        columns[x].render = (text, record) => {
          return (
            <div>
              {/* Mark Resolved/Unresolved */}
              {columns[x].actionTypes.indexOf('resolvedUnresolved') > -1 ? (
                <div>
                  <center>
                    <a title={`Mark as ${record.data.resolvedDate ? 'Unresolved' : 'Resolved'}`}>
                      {record.data.resolvedDate ? (
                        <Popover
                          title={<p>Resolved By {record.data.resolvedBy}</p>}
                          content={() => {
                            return (
                              <div>
                                <p>Date: {moment(new Date(record.data.resolvedDate)).format('YYYY-MM-DD HH:mm:ss')}</p>
                              </div>
                            )
                          }}
                        >
                          <CheckSquareOutlined
                            style={{ fontSize: 20, color: Theme.successColor }}
                            onClick={async () => {
                              let data = null

                              try {
                                await unresolveLog([record._id], Enums.VALUES_STRINGS.SPECIFIC)
                                data = updateViewData(this.state.data, [record._id], 'unresolve')
                                this.setState({ data })
                              } catch (e) {
                                message.error(e)
                              }
                            }}
                          />
                        </Popover>
                      ) : (
                        <CloseSquareOutlined
                          style={{ fontSize: 20, color: Theme.dangerColor }}
                          onClick={async () => {
                            let data = null

                            try {
                              await resolveLog([record._id], Enums.VALUES_STRINGS.SPECIFIC)
                              data = updateViewData(this.state.data, [record._id], 'resolve')
                              this.setState({ data })
                            } catch (e) {
                              message.error(e)
                            }
                          }}
                        />
                      )}
                    </a>
                  </center>
                </div>
              ) : null}
              {/* Email Agilit-e */}
              {columns[x].actionTypes.indexOf('email') > -1 ? (
                <div
                  className={
                    process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'
                  }
                >
                  {/* eslint-disable-next-line */}
                  <Popconfirm
                    title='Are you sure you want to send this Log to Agilit-e?'
                    onConfirm={() => this.mailAgilite(record)}
                  >
                    <a title='Email Agilit-e'>
                      <MailOutlined style={{ fontSize: 16, color: Theme.warningColor }} />
                    </a>
                  </Popconfirm>
                </div>
              ) : null}
              {/* Export Log Entry */}
              {columns[x].actionTypes.indexOf('export') > -1 ? (
                <div
                  className={
                    process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'
                  }
                >
                  {/* eslint-disable-next-line */}
                  <Divider type='vertical' />
                  <span
                    onClick={async () => {
                      try {
                        this.setState({ loading: true })
                        await handleExport(record.data)
                        this.setState({ loading: false })
                      } catch (e) {
                        message.error(e)
                        this.setState({ loading: false })
                      }
                    }}
                  >
                    <a title='Export Log Entry'>
                      <ExportOutlined style={{ fontSize: 16, color: Theme.dangerColor }} />
                    </a>
                  </span>
                </div>
              ) : null}
            </div>
          )
        }
      }
    }

    return columns
  }

  generateTableHeader() {
    return (
      <Row justify='space-between'>
        <Col span={3}>
          <Form.Item>
            <Select value='' onChange={(value) => this.markAllResolvedUnresolved(value)}>
              <Select.Option value=''>-Select Action-</Select.Option>
              {/* eslint-disable-next-line */}
              <Select.Option value='0' disabled={this.state.selectedRowKeys.length > 0 ? false : true}>
                Mark selected as resolved
              </Select.Option>
              <Select.Option value='1'>Mark all as resolved</Select.Option>
              {/* eslint-disable-next-line */}
              <Select.Option value='2' disabled={this.state.selectedRowKeys.length > 0 ? false : true}>
                Mark selected as unresolved
              </Select.Option>
              <Select.Option value='3'>Mark all as unresolved</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Tooltip title='Click to change Date Range'>
            <Input
              style={{ float: 'left', width: 210, cursor: 'pointer' }}
              value={`${moment(this.state.date[0]).format('YYYY-MM-DD')} - ${moment(this.state.date[1]).format(
                'YYYY-MM-DD'
              )}`}
              onClick={() => this.setState({ dateModalOpen: true, tmpDate: this.state.date })}
              readOnly
            />
          </Tooltip>
        </Col>
        <Col span={4}>
          <Form.Item label='Include Resolved Logs'>
            <Switch
              name='resolvedLogs'
              defaultChecked={false}
              onChange={(value) => {
                this.setState({ includeResolvedLogs: value })
                this.handleGetLogsExtended(this.state.date, value)
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Input
            name='search'
            placeholder='Search Logs...'
            defaultValue=''
            onChange={(e) => {
              const data = filterLogViewData(this.data, e.target.value)
              this.setState({ data })
            }}
          />
        </Col>
        <Col span={2}>
          <Tooltip title='Refresh Log Data'>
            <Button
              name='refresh'
              type='default'
              onClick={() => this.handleGetLogsExtended(this.state.date, this.state.includeResolvedLogs)}
              style={{ float: 'right' }}
            >
              <SyncOutlined
                style={{ fontSize: 20, fontWeight: 'bolder', color: Theme.successColor }}
                spin={this.state.tableLoading}
              />
            </Button>
          </Tooltip>
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <div>
        {this.generateTableHeader()}
        <Table
          pagination={{
            defaultCurrent: 1,
            defaultPageSize: 20,
            showSizeChanger: false
          }}
          columns={this.generateColumns()}
          dataSource={this.state.data}
          onChange={(pagination, filters, sorter, data) => {
            this.setState({ currentTableData: data.currentDataSource })
          }}
          loading={this.state.tableLoading}
          size='small'
          bordered
          rowSelection={{
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys) => this.setState({ selectedRowKeys })
          }}
          rowKey={(record) => record._id}
        />
        {this.state.record ? (
          <Modal
            title='Log Detail'
            okButtonProps={{ hidden: true }}
            visible={this.state.errorLogModalOpen}
            onOk={() => {
              this.setState({ errorLogModalOpen: false })
            }}
            onCancel={() => {
              this.setState({ errorLogModalOpen: false })
            }}
            cancelText='Close'
            maskClosable={false}
            width='50%'
            destroyOnClose
          >
            <ErrorLogsForm
              bodyTitle='Additional Information'
              record={this.state.record}
              categoryTitle='Category'
              subCategoryTitle='Sub Category'
              messageTitle='Message'
              statusTitle='Code'
            />
          </Modal>
        ) : null}
        <Modal
          title='Date Range'
          onOk={() => {
            this.setState({ date: this.state.tmpDate, dateModalOpen: false })
            this.handleGetLogsExtended(this.state.tmpDate, this.state.includeResolvedLogs)
          }}
          onCancel={() => this.setState({ dateModalOpen: false, tmpDate: this.state.date })}
          okText='Apply'
          okButtonProps={{ type: 'default', style: { backgroundColor: Theme.successColor, color: Theme.white } }}
          maskClosable={false}
          destroyOnClose
          visible={this.state.dateModalOpen}
        >
          <DatePicker.RangePicker
            style={{ width: '90%' }}
            value={this.state.tmpDate}
            onChange={(date) => this.handleDateChange(date)}
            inputReadOnly
          />
        </Modal>
      </div>
    )
  }
}

export default BatchLoggingLogView
